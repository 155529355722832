html {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 15px;
  margin: 0;
  line-height: 24px;
}

body {
  margin: 0;
  background-color: #efefef;
}

body,
html,
#app,
#app > div {
  height: 100%;
}

a {
  color: #ff4081;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.m-b-15 {
  margin-bottom: 15px;
}

.header {
  background: #296786;
  height: 74px;
  position: fixed;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.header .headerLogo {
  text-align: left;
  display: inline-block;
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.error-text {
  position: relative;
  top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.autocomplete-dropdown-container .suggestion-item,
.autocomplete-dropdown-container .suggestion-item--active {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  padding: 6px 5px;
}

.autocomplete-dropdown-container .suggestion-item--active:hover,
.autocomplete-dropdown-container .suggestion-item--active {
  background-color: rgb(93, 188, 210) !important;
  color: white;
}

.autocomplete-dropdown-container {
  margin-top: -5px;
}

.accordian-head {
  background: #f0f2f5;
  height: 52px;
  position: relative;
  margin-bottom: 6px;
  margin-top: 32px;
}

.accordian-head > div:first-child {
  display: block;
  height: 52px;
  padding: 14px 20px;
  color: #333e48;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}

.loader {
  position: absolute;
  left: 50%;
  top: 45%;
  z-index: 1;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.search-wrapper {
  input {
    height: 42px !important;
  }

  button {
    height: 42px !important;
    padding-top: 0px;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
